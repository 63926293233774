<template>
  <div class="waterfall clearfix" id="waterfall">
    <waterfall
      :col="col"
      :width="itemWidth"
      :gutterWidth="gutterWidth"
      :key="gutterWidth"
      :data="listData"
      :loadDistance="loadDistance"
      @loadmore="loadmore"
    >
      <!-- @scroll="scroll" -->
      <template>
        <a
          class="box"
          href="javascript:void(0)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <!-- @click="initSwiper(item.images)" -->
          <div class="pic-check" v-if="item.audit_status == 0">
            审核中
          </div>
          <div class="pic-check check-fail" v-if="item.audit_status == 2">
            审核未通过
          </div>
          <div class="pics-num" v-if="item.image_num > 1">
            <i class="icon-img"></i>{{ item.image_num }}
          </div>
          <!-- item.images -->
          <div class="pic-wrap">
            <!-- <img class="pic" :src="item.pic" /> -->
            <img
              class="pic"
              v-if="item.image"
              v-lazy="item.image"
              alt="加载错误1"
              @click="lightbox(item)"
            />
            <div class="shad-wrap clearfix" v-if="allowVote">
              <div class="vote right">
                <i class="icon-vote" @click.stop="addVote(item)"></i>
                <span>{{ item.vote_num }}</span>
              </div>
            </div>
          </div>
          <p class="txt"  @click="lightbox(item)">
            <img class="face" :src="item.avatar" />
            <span class="name">{{ item.nickname }}</span>
            <span class="time">{{ item.add_time }}</span>
          </p>
        </a>
      </template>
    </waterfall>
    <!-- <div v-if="isEnd">没有更多了...</div> -->
    <Lightbox
      :list="curImgList"
      :ids="editId"
      :groups="groupData"
      @display="changeDisplay"
      v-if="isShowLightbox"
    />

    <div class="video-dtl" v-if="isShowVideoBox">
      <input type="hidden" name="video_mi_id" value="17346" />
      <div class="video-wrap">
        <div class="info-wrap">
          <div class="title">{{ editId.mname }}</div>
          <div class="user">
            <img :src="editId.avatar" class="avatar" />
            <div class="username">{{ editId.nickname }}</div>
            <div class="time">{{ editId.add_time }}</div>
          </div>
        </div>
        <div class="video-box">
          <video
            id="video"
            width="1000"
            height="520"
            controls="controls"
            :src="editId.video"
            :poster="editId.image"
            class="video"
          >
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </div>
      <div class="tool-bar">
        <a href="javascript:void(0)" class="del" @click="del()">删除</a>
        <a
          href="javascript:void(0)"
          class="close"
          @click="isShowVideoBox = false"
        ></a>
      </div>
    </div>
    <!-- <Lightbox
      :list="curImgList"
      :ids="editId"
      @display="changeDisplay"
      v-if="isShowEdit"
    /> -->
  </div>
</template>

<script>
import Lightbox from "@/components/Lightbox";
export default {
  components: {
    Lightbox,
  },
  props: {
    allowVote: {
      type:Boolean,
      default:false
    },
    listData: {
      type: Array,
    },
    groupData: {
      type: Array,
    },
    // isEnd: Boolean
  },
  data() {
    return {
      col: 4,
      itemWidth: 330,
      gutterWidth: 20,
      loadDistance: 100,
      isShowLightbox: false,
      isShowVideoBox: false,
      isGroupImg: false,
      curImgList: [],
      workList: [],
      // 如果是我的作品，传入worksid mid gid
      editId: {},
      domItem: {},
      isShowEdit: false,
    };
  },
  watch: {
    listData: {
      immediate: true,
      handler(val) {
        this.workList = val;
      },
    },
  },
  methods: {
    addVote(item) {
      this.axios
        .post("/match/addVote", { id: item.id })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "点赞成功",
              type: "success",
            });
            item.has_vote = 1;
            item.vote_num += 1;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          //this.tools.ajax_error(err);
        });
      // e.stopPropagation();
    },
    // scroll() {
    //   // this.loadmore();
    //   console.log(1);
    // },
    switchCol(col) {
      this.col = col;
      // console.log(this.col);
    },
    loadmore() {
      this.$emit("useGetData");
      // console.log("works", this.listData);
      // this.worksList = this.worksList.concat(this.listData);
      // this.$waterfall.forceUpdate();
    },
    lightbox(item) {
      this.editId = item;
      let isVideo =
        item.works_type == 4 && this.$route.name == "myworks" ? true : false;
      // 如果非视频内容
      if (item.m_id != undefined && item.group != undefined) {
        this.editId.work_id = item.id;
        this.editId.video = "";
        // this.editId.m_id = item.m_id;
        // this.editId.group = item.group;
        // this.editId.works_type = item.works_type;
      }
      if (!isVideo) {
        this.curImgList = item.images;
        if (item.works_type != 3) {
          for (var i = 0; i < this.curImgList.length; i++) {
            this.curImgList[i].title = item.mname;
            this.curImgList[i].describe = item.remark;
          }
        }
        this.isShowLightbox = true;
      } else {
        this.tools.getWorksDetail({ work_id: this.editId.id }).then((res) => {
          this.editId.video = res.form.video;
          this.isShowVideoBox = true;
        });
      }
      // 如果视频内容
    },
    changeDisplay() {
      this.isShowLightbox = false;
    },
    del() {
      this.$confirm("您确定要删除该视频？ 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/match/delmyworks", { work_id: this.editId.id })
            .then((res) => {
              // console.log(res);
              if (res.data.code == 200 && res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                window.location.reload();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  created() {
    // console.log("waterfall: ", this.listData);
    if (document.querySelector("body").clientWidth <= 1600) {
      this.itemWidth = 285;
    }
    this.loadmore();
    // this.worksList = this.listData;
    // this.$waterfall.forceUpdate();
  },
};
</script>

<style>
.waterfall .box {
  position: relative;
}
.pic-check {
  position: absolute;
  top: 10px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  color: #fff;
  line-height: 28px;
  height: 28px;
  padding: 0 15px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
.check-fail {
  background: rgba(255, 0, 0, 0.5);
}

html {
  height: auto !important;
}
</style>
