<template>
  <div class="bd desc-box" id="contest-bd">
    <div class="text" v-html="content"></div>
    <div class="btn-wrap" v-if="!isShowIntroContribute">
      <a class="btn" href="javascript:;" @click="contribute()">我要投稿</a>
      <a
        class="btn btn-join btn-group"
        href="javascript:;"
        v-if="isShowIntroGroup"
        @click="contributeGroup()"
        >战队投稿</a
      >
      <br />
      <a
        class="btn-inter"
        href="javascript:;"
        @click="showForm"
        v-if="is_globalVote == 1"
        >INTERNATIONAL ENTRANCE</a
      >
    </div>
    <el-dialog
      title="港澳台地區、華人華僑及國際友人投稿入口"
      :visible.sync="isShowForm"
      :modal-append-to-body="false"
      class="dialog-basic international"
    >
      <el-form :model="form" :rules="infoRules" ref="form" label-width="120px">
        <el-form-item label="姓名/NAME" prop="name">
          <el-input
            v-model="form.name"
            placeholder="Please enter your name"
          ></el-input>
        </el-form-item>
        <el-form-item label="E-MAIL" prop="email">
          <el-input
            v-model="form.email"
            placeholder="Please enter your e-mail addresss"
          ></el-input>
        </el-form-item>
        <div class="tips-item">
          <p>
            Please send the above information and your entry to the email
            address:
            <span style="color:red;text-decoration:underline;"
              >460566298@qq.com</span
            >
          </p>
          <p>
            請將以下信息和您的參賽作品發送到郵箱：<span
              style="color:red;text-decoration:underline;"
              >460566298@qq.com</span
            >
          </p>
        </div>
        <div class="tips-item tips-list" style="font-weight: bold;">
          <p>1.作品標題/PHOTO TITLE(no more than 15 words)</p>
          <p>2.作者/Author</p>
          <p>3.作者所在地/author's location</p>
          <p>4.照片拍攝地/Photo's location</p>
        </div>
        <div class="tips-item">
          <p>
            <span style="color: red">*</span> The entrance is limited to
            overseas visitors, and mainland Chinese are limited to
            submittingthrough the official submission of the website.
          </p>
          <p>
            (該入口僅限海外人士，中國大陸人士僅限於通過網站官方投稿入口投稿)
          </p>
        </div>
        <div class="btn-wrap">
          <a
            class="btn btn-cfirm"
            href="javascript:void(0)"
            @click="submitBasic()"
            >同意並提交/Agree and submit</a
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShowIntroContribute: {
      type: Boolean,
    },
    isShowIntroGroup: {
      type: Boolean,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      content: "",
      is_globalVote: 0,
      isShowForm: false,
      // 基础信息表单
      form: {
        name: "",
        email: "",
      },
      infoRules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        email: [{ required: true, message: "邮箱不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    init() {
      this.tools.getDetail({ id: this.id }).then((res) => {
        this.content = res.requirements;
        this.is_globalVote = res.is_globalVote;
      });
    },
    contribute() {
      this.$emit("triggerContribute");
    },
    contributeGroup() {
      this.$emit("triggerGroup");
    },
    updateIntro(data) {
      this.$emit("updateIndex", data);
    },
    submitBasic() {
      if (this.form.name != "" && this.form.email != "") {
        this.form.m_id = this.id;
        this.axios.post("/match/entrance", this.form).then((res) => {
          if (res.data.code == 200) {
            this.$message("提交成功");
            this.isShowForm = false;
          }
        });
      } else {
        this.$message.error("参数不能为空");
      }
    },
    showForm() {
      this.isShowForm = true;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style>
.btn-inter {
  font-size: 18px;
  color: #ef7f48;
  margin-top: 20px;
  display: inline-block;
}
.international p {
  font-size: 16px;
}
.tips-list {
  margin-bottom: 15px;
}
.tips-list p {
  line-height: 22px;
  margin-bottom: 3px;
}
.ql-align-center {
  text-align: center;
}
</style>
