<template>
  <div class="bd allphotos">
    <div class="clearfix">
      <div class="tab left" v-if="worksGroup.length > 1">
        <a
          :class="gid == ''? 'on' : ''"
          href="javascript:;"
          @click="filterWoks('')"
          >全部</a
        >
        <span v-for="(item, index) in worksGroup" :key="index">
          <span class="icon-line">|</span>
          <a
            :class="gid == item.gid ? 'on' : ''"
            href="javascript:;"
            @click="filterWoks(item.gid)"
            >{{ item.groupname }}</a
          >
        </span>
      </div>
      <!-- <div class="right">
        <a class="to-rank" href="rank.html">投票排行榜</a>
      </div> -->
    </div>
    <Waterfall :listData="works" :groupData="worksGroup" @useGetData="getData" />
  </div>
</template>

<script>
import Waterfall from "@/components/Waterfall";
export default {
  components: {
    Waterfall
  },
  props: {
    worksGroup: {
      type: Array
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      works: [],
      // 是否到底部
      end: false,
      gid: "",
      page: 1,
      totalPages:2,
      pagesize: 20, // 默认每页20条数据
      total: 0,
      loading:true,
    };
  },
  methods: {
    filterWoks(gid) {
        if (this.gid != gid) {
            this.$router.push({ query: {group: gid } });
        }
        this.gid = gid;
        this.page = 1;
        this.end = false;
        this.works = [];
        // 重新筛选
        this.getData();
    },
    getList(res) {
        this.works = this.works.concat(res);
        this.loading = true;
        if (this.page < this.totalPages) {
            this.page++;
        }
        else {
            this.end = true;
        }
    },
    getData() {
        if (this.end || !this.loading) {
            return false;
        }
        this.loading = false;
        this.axios
            .post("/match/myworks?group=" + this.gid,{id:this.id,page:this.page,pagesize:this.pagesize})
            .then(res => {
                var data = res.data.data;
                if(this.page <= 1){
                    this.total = data.total;
                    this.totalPages = data.totalPages;
                }
                this.getList(data.list);
            })
            .catch(err => {
                this.tools.ajax_error(err);
            });
    },
  },
  mounted() {
      //this.cur = this.$route.query.index;
      this.gid = this.$route.query.group;
      if (this.gid == undefined) {
          this.gid = '';
      }
  }
};
</script>

<style></style>
