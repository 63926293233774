<template>
  <div class="bd allphotos">
    <div class="clearfix">
      <div class="tab left" v-if="worksGroup.length > 1">
        <a
          :class="gid == '' ? 'on' : ''"
          href="javascript:;"
          @click="filterWoks('')"
          >全部</a
        >
        <span v-for="(item, index) in worksGroup" :key="index">
          <span class="icon-line">|</span>
          <a
            :class="gid == item.gid ? 'on' : ''"
            href="javascript:void (0);"
            @click="filterWoks(item.gid)"
            >{{ item.groupname }}</a
          >
        </span>
        <!-- <a class="on" href="">全部</a><span class="icon-line">|</span>
        <a href="">人文组</a><span class="icon-line">|</span>
        <a href="">风景组</a><span class="icon-line">|</span>
        <a href="">动物组</a> -->
      </div>
      <div class="right" v-if="detail.is_vote == 1">
        <a href="rank.html"></a>
        <router-link class="to-rank" :to="'/rank/' + id"
          >投票排行榜</router-link
        >
      </div>
    </div>
    <Waterfall :listData="works" @useGetData="getData" :allowVote="detail.is_vote ==1 ? true: false"/>
  </div>
</template>

<script>
import Waterfall from "@/components/Waterfall";
// import { getWorks } from "@/utils/ajax";
export default {
  components: {
    Waterfall
  },
  props: {
    worksGroup: {
      type: Array
    }
  },
  data() {
    return {
      works: [],
      matchGroup: [],
      totalPage: 0,
      id: this.$route.params.id,
        detail:{},
      // 是否到底部
      end: false,
      gid: "",
      page: 1,
      totalPages:2,
      // totalPage: 0, // 总页数
      pagesize: 20, // 默认每页20条数据
      total: 0,
        loading:true,
    };
  },
  methods: {
    filterWoks(gid) {
        if (this.gid != gid) {
            this.$router.push({ query: {group: gid } });
        }
      this.gid = gid;
      this.page = 1;
      this.end = false;
      this.works = [];
      // 重新筛选
      this.getData();
    },
    getList(res) {
        this.works = this.works.concat(res);
        this.loading = true;
        if (this.page < this.totalPages) {
        this.page++;
      }
       else {
        this.end = true;
      }
    },
    getData() {
      if (this.end || !this.loading) {
        return false;
      }
        this.loading = false;
        this.axios
            .post("/match/works?group=" + this.gid,{id:this.id,page:this.page,pagesize:this.pagesize})
            .then(res => {
                var data = res.data.data;
                if(this.page <= 1){
                    this.total = data.total;
                    this.totalPages = data.totalPages;
                }
                this.getList(data.list);
            })
            .catch(err => {
                console.log(err);
            });
    }
  },
  mounted() {
      this.tools.getDetail({id:this.id}).then(res=>{
          this.detail = Object.assign(this.detail,res);
      })
    this.gid = this.$route.query.group;
    if (this.gid == undefined) {
        this.gid = '';
    }
    // this.filterWoks(this.gid);
  }
};
</script>

<style></style>
