<template>
    <div class="bd desc-box" id="contest-bd">
        <div class="text" v-html="detail.notice_web"></div>
    </div>
</template>

<script>
    export default {
        props: {
            isShowIntroContribute: {
                type: Boolean,
            },
        },
        data() {
            return {
                id: this.$route.params.id,
                detail: {
                    notice_web: "",
                },
            };
        },
        methods: {
            init() {
                this.tools.getDetail({ id: this.id }).then((res) => {
                    this.detail = Object.assign(this.detail,res);
                });
            },
        },
        created() {
            this.init();
        },
    };
</script>

<style>
    .btn-inter {
        font-size: 18px;
        color: #ef7f48;
        margin-top: 20px;
        display: inline-block;
    }
    .international p {
        font-size: 16px;
    }
    .tips-list {
        margin-bottom: 15px;
    }
    .tips-list p {
        line-height: 22px;
        margin-bottom: 3px;
    }
</style>
