<template>
  <div class="bd desc-box" id="group-info">
    <div class="group-info-con" :style="`background: url(https://match.pai2345.com/static/images/team/group_${mid}.png) no-repeat top center`">
      <div class="group-item" v-for="(item,index) in groups" :key="index">
        <div class="group-name"><span><em>{{item.groupName}}</em></span></div>
        <div class="group-leader">
          <div class="label">团长</div>
          <div>{{item.leader}}</div>
        </div>
        <div class="group-members">
          <div class="label">团员</div>
          <ul>
            <li v-for="(subItem,SubIdx) in item.members" :key="SubIdx">{{subItem}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShowIntroContribute: {
      type: Boolean,
    },
    isShowIntroGroup: {
      type: Boolean,
    },
  },
  data() {
    return {
      groups:[],
      mid: 0,
    };
  },
  methods: {
   
  },
  created() {
    // 获取战队信息
    let that = this;
    this.mid = this.$route.params.id;
    this.axios.post("/team/teamList", { mid: this.$route.params.id }).then((res) => {
      that.groups = res.data.data;
    });
  },
};
</script>

<style scoped>
.page{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page>span{
  background: #eee;
  color: #666;
  height: 30px;
  line-height: 30px;
  min-width: 20px;
  padding: 0 5px;
  display: inline-block;
  text-align: center;
  margin: 0 3px;
  border-radius: 3px;
  cursor: pointer;
}
.page>span:hover{
  background: #ef7f48;
  color: #fff;
}
.page>span.active{
   background: #ef7f48;
   color: #fff;
}

.group-info-con{
  width: 100%;
  min-height: 220px;
  background-size: 100% auto;
  padding-top: 350px;
}
.group-item{
  width: 880px;
  margin: 120px auto;
}
.group-item .group-name{
  margin:0 auto;
  text-align: center;
  margin-bottom: 50px;
}
.group-item .group-name span{
  height:70px;
  line-height: 70px;
  padding: 0 50px 0 20px;
  display: inline-block;
  background: #15559a;
  color: #fff;
  font-size: 28px;
  position: relative;
}
.group-item .group-name span em{
  position: relative;
  z-index: 1;
}
.group-item .group-name span::before{
  content: "";
  width:50px;
  height:70px;
  display: inline-block;
  background: url(../../assets/images/a-1.png) no-repeat top center;
  background-size: auto 100%;
  position: absolute;
  left: -40px;
  top: 0;
  z-index: 0;
}
.group-item .group-name span::after{
  content: "";
  width: 100%;
  height: 70px;
  display: inline-block;
  border: 2px solid #15559a;
  position:absolute;
  right: -13px;
  top: 10px;
  z-index: 0;
}
.group-leader{
  width: 100%;
  height: 70px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  background-color: #95b3d7;
  color: #fff;
  font-size: 25px;
  position: relative;
}
.group-leader::before{
  content: "";
  width: 38px;
  height: 32px;
  display: inline-block;
  background: url(../../assets/images/a-2.png) no-repeat top center;
  background-size: auto 100%;
  position:absolute;
  left: 0;
  top: 70px;
}
.group-leader::after{
  content: "";
  width: 38px;
  height: 32px;
  display: inline-block;
  background: url(../../assets/images/a-3.png) no-repeat top center;
  background-size: auto 100%;
  position:absolute;
  right: 0;
  top: 70px;
}
.group-leader>div{
  text-align: center;
  line-height: 70px;
  width:500px;
}
.group-leader .label{
  width: 290px;
  border-right: 2px solid #fff;
}
.group-members{
  width:800px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  border:2px solid #eee;
  border-top: 0;
  margin: 0 auto;
  font-size: 20px;
}
.group-members .label{
  width: 200px;
  background:#fff;
  text-align: center;
}
.group-members li{
  width:600px;
  text-align: center;
  height:45px;
  line-height:45px;
  background-color: #f2f2f2;
  margin-top: 2px;
}
</style>

