<template>
  <div class="bd winphotos">
    <div class="clearfix">
      <div class="tab left" v-if="worksGroup.length > 1">
        <a
          :class="cur == -1 ? 'on' : ''"
          href="javascript:;"
          @click="filterWoks(-1, '')"
          >全部</a
        >
        <span v-for="(item, index) in worksGroup" :key="index">
          <span class="icon-line">|</span>
          <a
            :class="cur == index ? 'on' : ''"
            href="javascript:;"
            @click="filterWoks(index, item.gid)"
            >{{ item.groupname }}</a
          >
        </span>
      </div>
    </div>

    <WaterfallAward :listData="works" @useGetData="getData" />
  </div>
</template>

<script>
import WaterfallAward from "@/components/WaterfallAward";
export default {
  components: {
    WaterfallAward
  },
  props: {
    worksGroup: {
      type: Array
    }
  },
  data() {
    return {
      works: [],
      totalPage: 0,
      id: this.$route.params.id,
      // 是否到底部
      cur: -1,
      end: false,
        gid:0,
        page: 1,
        totalPages:2,
        // totalPage: 0, // 总页数
        pagesize: 20, // 默认每页20条数据
        total: 0,
        loading:true,
    };
  },
  methods: {
    filterWoks(index, gid) {
        this.cur = index;
        if (this.gid != gid) {
            this.$router.push({ query: {group: gid } });
        }
        this.gid = gid;
        this.page = 1;
        this.end = false;
        this.works = [];
        // 重新筛选
        this.getData();
      // this.cur = index;
      // this.gid =  gid;
      // this.getData(gid);
    },
      getList(res) {
          this.works = this.works.concat(res);
          this.loading = true;
          if (this.page < this.totalPages) {
              this.page++;
          }
          else {
              this.end = true;
          }
      },
    getData() {
        if (this.end || !this.loading) {
            return false;
        }
        this.loading = false;
        this.axios
            .post("/match/winworks?id=" + this.id,{group:this.gid,page:this.page,pagesize:this.pagesize})
            .then(res => {
                var data = res.data.data;
                if(this.page <= 1){
                    this.total = data.total;
                    this.totalPages = data.totalPages;
                }
                this.getList(data.list);
            })
            .catch(err => {
                console.log(err);
            });
    }
  },
  created() {
      this.gid = this.$route.query.group;
      if (this.gid == undefined) {
          this.gid = '';
      }
      //this.filterWoks(this.gid);

  }
};
</script>

<style></style>
