<template>
  <ul class="win-list clearfix">
    <waterfall
      :col="col"
      :width="itemWidth"
      :gutterWidth="gutterWidth"
      :key="gutterWidth"
      :data="listData"
      :loadDistance="loadDistance"
      @loadmore="loadmore"
    >
      <!-- @scroll="scroll" -->
      <template>
        <li
          v-for="(item, index) in listData"
          :key="index"
          @click="lightbox(item)"
        >
          <a class="win-item" href="javascript:void(0)">
            <div class="pic-wrap">
              <img
                class="pic"
                v-if="item.image"
                v-lazy="item.image"
                alt="加载错误"
              />
            </div>
            <div class="txt-wrap">
              <img class="face" :src="item.avatar" />
              <span class="name">{{ item.nickname }}</span>
              <span class="time">{{ item.add_time }}</span>
              <!-- <p class="rank first">一等奖</p> -->
              <p class="rank">{{ item.gradename }}</p>
            </div>
          </a>
        </li>
      </template>
    </waterfall>
    <!-- <div v-if="isEnd">没有更多了...</div> -->
    <Lightbox
      :list="curImgList"
      @display="changeDisplay"
      v-if="isShowLightbox"
    />
  </ul>
</template>

<script>
import Lightbox from "@/components/Lightbox";
import Vue from "vue";
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

// or with options
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: 'dist/loading.gif',
    attempt: 1
});
export default {
  components: {
    Lightbox
  },
  props: {
    listData: {
      type: Array
    }
    // isEnd: Boolean
  },
  data() {
    return {
      col: 3,
      itemWidth: 446,
      gutterWidth: 21,
      loadDistance: 100,
      isShowLightbox: false,
      isGroupImg: false,
      curImgList: [],
      worksList: []
    };
  },
  watch: {
    listData: {
      immediate: true,
      handler(val) {
        this.workList = val;
        // console.log("val:", val);
      }
    }
  },
  methods: {
    // scroll() {
    //   // this.loadmore();
    //   console.log(1);
    // },
    switchCol(col) {
      this.col = col;
      // console.log(this.col);
    },
    loadmore() {
      this.$emit("useGetData");
      // console.log("works", this.listData);
      // this.worksList = this.worksList.concat(this.listData);
      // this.$waterfall.forceUpdate();
    },
    lightbox(item) {
      // console.log(item.images);
      this.isShowLightbox = true;
      this.curImgList = item.images;
    },
    changeDisplay() {
      this.isShowLightbox = false;
    }
  },
  created() {
    if (document.querySelector("body").clientWidth <= 1600) {
      this.itemWidth = 386;
    }
    // console.log(this.listData);
    // this.loadmore();
    // this.worksList = this.listData;
    // this.$waterfall.forceUpdate();
  }
};
</script>

<style>
.waterfall .box {
  position: relative;
}
html{
  height: auto !important;
}
</style>
