<template>
  <div class="content">
    <a
      class="btn-tg"
      v-if="detail.button_show"
      href="javascript:;"
      @click="contribute(0)"
      >我要投稿</a
    >
    <a
      class="btn-tg btn-group1"
      v-if="detail.button_show && isShowGroupContribute"
      href="javascript:;"
      @click="contribute(1)"
      ></a
    >
    <div class="contest-description">
      <div class="wrap">
        <div class="contest-banner">
          <img :src="detail.image" />
          <span class="sta sta-ing" v-if="detail.tg_status == 0">即将开始</span>
          <span class="sta sta-ing" v-if="detail.tg_status == 1">投稿中</span>
          <span class="sta sta-over" v-if="detail.tg_status == 2">已截稿</span>
          <div class="btn-wrap" v-if="detail.button_show">
            <a class="btn btn-join" href="javascript:;" @click="contribute(0)">我要投稿</a>
            <a class="btn btn-org" href="javascript:;" v-if="detail.has_award==1" @click="toUploadOriImg">获奖大图上传</a>
            <a class="btn btn-join btn-group" v-if="isShowGroupContribute" href="javascript:;" @click="contribute(1)" >战队投稿</a>
            <a class="btn btn-org" href="javascript:;" v-if="detail.is_assoc_show" @click="isShowSelorg = true" >所属单位</a>
          </div>
          <div class="share">
            <div class="weibo share-item" @click="share('weibo')">
              <p class="h1"><img :src="imgWeibo" alt="" /></p>
            </div>
            <div
              class="weixin share-item"
              @mouseenter="showQrcode()"
              @mouseleave="clearQrcode()"
            >
              <p class="h1"><img :src="imgWx" alt="" /></p>
            </div>
            <div id="qrcode" ref="qrcode"></div>
            <div class="qq share-item" @click="share('qq')">
              <p class="h1"><img :src="imgQq" alt="" /></p>
            </div>
          </div>
        </div>
        <div class="contest-con" id="contestP">
          <div class="hd">
            <router-link
              :to="'/detail/' + id + '/intro'"
              @click.native="isMyworksActive = false"
              >赛事介绍</router-link
            ><span class="icon-line">|</span>
            <router-link v-if="detail.button_show && isShowGroupContribute"
              :to="'/detail/' + id + '/groupinfo'"
              @click.native="isMyworksActive = false"
              >战队信息</router-link
            ><span class="icon-line" v-if="detail.button_show && isShowGroupContribute">|</span>
            <router-link v-if="!isShowGroupContribute && detail.is_works_show == 1"
              :to="'/detail/' + id + '/works'"
              @click.native="isMyworksActive = false"
              >已投稿作品</router-link
            ><span class="icon-line" v-if="!isShowGroupContribute && detail.is_works_show == 1">|</span>
            <!-- <router-link @click="goMyworks">我的作品</router-link> -->
            <a
              href="javascript:;"
              :class="
                isMyworksActive
                  ? 'router-link-exact-active router-link-active'
                  : ''
              "
              @click="goMyworks"
              >我的作品</a
            >
            <span v-if="detail.is_popup == 0 && detail.prizes_rs == 1 && detail.notice_link == ''"
              ><span class="icon-line">|</span
              ><router-link :to="'/detail/' + id + '/award'">{{
                detail.notice_title
              }}</router-link></span
            >
            <span v-if="detail.is_popup == 1 && detail.notice_link == '' && detail.notice_show == 1"
              ><span class="icon-line">|</span
              ><router-link :to="'/detail/' + id + '/notice'">{{
                detail.notice_title
              }}</router-link></span
            >
            <span v-if="detail.notice_link != '' && detail.notice_show == 1"
              ><span class="icon-line">|</span
              ><a :href="detail.notice_link" target="_blank">{{
                detail.notice_title
              }}</a></span
            >
          </div>
          <router-view
            @triggerContribute="contribute(0)"
            @triggerGroup="contribute(1)"
            :isShowIntroContribute="detail.button_show == 0"
            :isShowIntroGroup="isShowGroupContribute"
            :worksGroup="detail.match_group"
          ></router-view>
        </div>
      </div>
    </div>

    <!-- 投稿基础信息核对 -->
    <el-dialog
      title="投稿信息基础核对"
      :visible.sync="isShowForm"
      :modal-append-to-body="false"
      class="dialog-basic"
    >
      <el-form
        :model="basicInfo"
        :rules="infoRules"
        ref="basicInfo"
        label-width="80px"
        class="basic-info"
      >
        <el-form-item label="昵称" prop="nickname">
          <el-input
            v-model="basicInfo.nickname"
            placeholder="点击输入您的昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realname">
          <el-input
            v-model="basicInfo.realname"
            placeholder="点击输入您的真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地" prop="province">
          <el-select
            class="select"
            v-model="basicInfo.province"
            placeholder="请选择省份"
            @change="initCity(basicInfo.province)"
          >
            <el-option
              v-for="(item, index) in province"
              :key="index"
              :label="index"
              :value="index"
            ></el-option>
          </el-select>
          <el-select v-model="basicInfo.city" placeholder="请选择地级市">
            <el-option
              v-for="(item, index) in city"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="btn-wrap">
          <a
            class="btn btn-cfirm"
            href="javascript:void(0)"
            @click="submitBasic()"
            >确定提交</a
          >
        </div>
      </el-form>
    </el-dialog>

    <!-- 投稿组别 -->
    <el-dialog
      title="请选择投稿类型"
      :visible.sync="isShowContributeType"
      :modal-append-to-body="false"
      class="dialog-type"
    >
      <div class="btn-list">
        <a
          href="javascript:;"
          class="btn-tgtype"
          v-for="(item, index) in detail.match_group"
          :key="index"
          @click="goContribute(item.gid)"
          >{{ item.groupname }}</a
        >
      </div>
    </el-dialog>

    <!-- 协会弹窗 -->
    <el-dialog
      title="请选择所属单位"
      :visible.sync="isShowSelorg"
      :modal-append-to-body="false"
      class="dialog-selorg"
    >
      <ul class="org-list" v-if="detail.orgLevel == 1">
        <li
          :class="{ cur: detail.assoc == item}"
          v-for="item in detail.orgList"
          :key="item"
          @click="changeTab(item)"
        >
          {{ item }}
        </li>
      </ul>

      <div style="text-align: center;" v-if="detail.orgLevel == 2">
        <el-cascader
          expand-trigger="hover"
          :options="selorgColumns"
          separator="_"
          placeholder="请选择所属单位"
          v-model="selorgValue"
          @change="changeTab">
        </el-cascader>
      </div>

      <div class="btn-wrap">
        <a
          class="btn btn-cfirm"
          href="javascript:void(0)"
          @click="submitSociety()"
          >确定</a
        >
      </div>
    </el-dialog>

    <!-- 俱乐部弹窗 -->
    <el-dialog
      title="温馨提示："
      :visible.sync="allowClubDialog"
      :modal-append-to-body="false"
      class="dialog-type"
    >
      <div class="btn-list" style="font-size:16px;height:150px">
        根据征稿要求，本次活动仅限大扬影像会员、大扬学院会员及五六公社社员参加，热诚欢迎各地影友入会，积极投稿参赛。
        <br />入会联系人：温晓斌
        <br />联系电话：18060778759
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import Head from "@/layouts/Head.vue";
// import Lightbox from "@/components/Lightbox";
import utils from "@/utils/";
import QRCode from "qrcodejs2";
import store from "@/store";

export default {
  name: "Detail",
  components: {
    // Lightbox
  },
  data() {
    return {
      // 缓存 matchList 初始化列表，用 list 筛选赋值 matchList
      id: this.$route.params.id,
      detail: {
        image: "",
        status: "",
        tg_status: 0,
        button_show: 0,
        prizes_rs: 0,
        match_group: [],
        notice_title: "获奖作品",
        is_popup: 0,
        notice_show: 0,
        assoc: "",
        club: "",
        has_award: "",
      },

      // 是否隐藏投稿按钮
      isHideContribute: false,
      // 分享
      imgWeibo: require("@/static/images/weibo.png"),
      imgWx: require("@/static/images/weixin.png"),
      imgQq: require("@/static/images/qq.png"),
      // 是否显示获奖作品
      isShowAward: false,
      // 协会相关
      isShowSelorg: false,
      selorgColumns:[],
      selorgValue:[],
      isValidated: true,
      // 是否显示登记基础信息的弹层
      isShowForm: false,
      // 基础信息表单
      basicInfo: {
        nickname: "",
        realname: "",
        province: "",
        city: "",
        mobile: 1,
      },
      infoRules: {
        nickname: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        username: [
          { required: true, message: "真实姓名不能为空", trigger: "blur" },
        ],
        province: [],
        city: [],
        // phone: [{ required: true, message: "手机号不能为空", trigger: "blur" }]
      },
      // 投票类型 弹层开关
      isShowContributeType: false,
      // matchGroupList: [
      // ],
      matchGroup: [],
      type: "",
      // 省市级联
      province: [],
      city: [],
      // 是否战队赛
      isShowGroupContribute: false,
      uid: 0,
      isMyworksActive: false,
      gid: 0,
      // 是否俱乐部投稿
      allowClub: false,
      allowClubDialog: false,
    };
  },
  methods: {
    init() {
      this.tools.detail_cache({ id: this.id }, true).then((res) => {
        this.detail = Object.assign(this.detail, res);
        // console.log(this.detail);
        // 判断是否战队投稿
        if(this.detail.contribution == 1){
          this.isShowGroupContribute = true;
        }
        // 判断俱乐部投稿
        if(this.detail.club != ''){
          this.allowClub = true;
        }
        this.gid = this.detail.match_group[0].gid;
        if (this.detail.mname != undefined && this.detail.mname != "") {
          window.document.title = this.detail.mname;
        }
        // 协会初始化
        // console.log(this.detail.orgList2);
        if(this.detail.orgLevel == 2){
          for(let key in this.detail.orgList){
            var children_arr = [];
            // console.log(this.detail.orgList[key]);
            for(let key2 in this.detail.orgList2[this.detail.orgList[key]]){
              // console.log('------------'+this.detail.orgList2[this.detail.orgList[key]][key2]);
              children_arr.push({
                value: this.detail.orgList2[this.detail.orgList[key]][key2],
                label: this.detail.orgList2[this.detail.orgList[key]][key2],
              });
            }
            this.selorgColumns.push({
              value: this.detail.orgList[key],
              label: this.detail.orgList[key],
              children: children_arr,
            });
          }

          let assoc = this.detail.assoc.split("_");
          for(let i in assoc){
            this.selorgValue.push(assoc[i]);
          }
        }
      });
      // 省市初始化
      this.tools.getAreas().then((res) => {
        this.province = res;
      });
    },
    initCity(province) {
      this.city = this.province[province];
      this.basicInfo.city = "";
    },
    changeTab(item) {
      let that = this;
      if(that.detail.orgLevel == 2){
        that.detail.assoc = item[0]+'_'+item[1];
        that.selorgValue = item;
      }else{
        that.detail.assoc = item;
      }
      // console.log(that.detail.assoc);
    },
    // 选择所属协会
    submitSociety() {
      let that = this;
      this.axios.post("/match/editAssoc", {match_id: that.id, assoc: that.detail.assoc}).then((res) => {
        this.isShowSelorg = false;
        if (res.data.code != 200) {
          this.detail.assoc = "";
          return false;
        }
      });
    },
    // 提交基础信息核对
    submitBasic() {
      // let that = this;
      this.$refs["basicInfo"].validate((valid) => {
        if (valid) {
          // console.log(that.basicInfo);
          this.axios.post("/match/setUserInfo", this.basicInfo).then((res) => {
            if (res.data.code == 200 || res.code == 200) {
              this.isShowForm = false;
              if (this.isGroup) {
                this.contribute(1);
              } else {
                this.contribute(0);
              }
            }
            if (res.data.code == 0 || res.code == 0) {
              // console.log(res.data.msg);
              this.$message(res.data.msg);
            }
          });
        } else {
          this.$message("信息未填写完整");
        }
      });
    },
    contribute(isGroup) {
      let that = this;
      // 判断是否协会投稿
      if(this.detail.is_assoc_show && !this.detail.assoc){
        setTimeout(function(){
          that.isShowSelorg = true;
        }, 1000);
      }
      // 判断是否战队投稿
      if (isGroup != undefined && isGroup == 1) {
        this.isGroup = true;
      } else {
        this.isGroup = false;
      }
      // 判断是否登录，并且是否登记完成基础信息
      if (this.detail.tg_status == 0) {
        this.$message("本赛事还未开始，请稍候...");
      } else if (this.detail.tg_status == 2) {
        this.$message("本赛事已截稿");
      } else {
        // this.checkUserInfo(this.contributeGroup);
        if (this.isShowGroupContribute) {
          this.checkUserInfo(this.contributeGroup);
        } else {
          this.checkUserInfo(() => {
            this.dealMatchGroup();
          });
        }
      }
    },
    dealMatchGroup() {
      var fase = true;
      if (this.detail.limit_province && this.detail.limit_province != "") {
        if (this.detail.limit_province != this.basicInfo.province) {
          fase = false;
          this.$alert(
            "当前赛事仅支持" +
              this.detail.limit_province +
              "影友投稿<br/>" +
              "请至个人中心确认您的所在地",
            "提示",
            {
              dangerouslyUseHTMLString: true,
              type: "warning",
              center: true,
            }
          );
        } else if (
          this.detail.limit_city &&
          this.detail.limit_city != "" &&
          this.detail.limit_city != this.basicInfo.city
        ) {
          fase = false;
          this.$alert(
            "当前赛事仅支持" +
              this.detail.limit_city +
              "影友投稿<br/>" +
              "请至个人中心确认您的所在地",
            "提示",
            {
              dangerouslyUseHTMLString: true,
              type: "warning",
              center: true,
            }
          );
        }
      }
      if (fase) {
        var matchGroup = this.detail.match_group;
        if (matchGroup.length > 1) {
          // 如果大于1，显示赛事投稿类型 弹层
          this.isShowContributeType = true;
          // 选择分类后，根据gid跳转
        } else {
          // 如果小于1，直接根据gid跳转投稿页
          // matchGroup应该至少=1；为空则为数据错误
          this.goContribute(matchGroup[0].gid);
        }
      }
    },
    goContribute(gid) {
      var group = this.tools.return_group(this.detail.match_group, gid);
      if (group) {
        let type = group.type;
        let theme = group.theme;
        let isGroup = group.is_group_des;
        // 专题赛事
        if(theme != ''){
          this.$router.push({
            path: `/theme/${theme}/${this.id}`,
            query: { group: gid },
          })
          return false;
        }
        if (type.length == 1) {
          // 判断是否组照,直接跳转到对应页面
          this.linkTo(type[0], isGroup, gid);
        } else {
          // 判断有几个投稿类型，显示对应选择类型页
          // this.$
          let paramsType = "";
          paramsType += type.indexOf("1") > -1 ? "single" : "";
          paramsType +=
            type.indexOf("2") > -1 ? (isGroup == 0 ? "multi" : "group") : "";
          paramsType += type.indexOf("3") > -1 ? "video" : "";
          this.$router.push({
            path: `/contribute/${this.id}/${paramsType}`,
            query: { group: gid },
          });
        }
      }
    },
    linkTo(type, isGroup, gid) {
      switch (type[0]) {
        case "1":
          // "/contribute/" + this.$route.params.id + "/single"
          this.$router.push({
            path: `/contribute/${this.id}/0/single`,
            query: { group: gid },
          });
          break;
        case "2":
          isGroup == 0
            ? this.$router.push({
                path: `/contribute/${this.id}/0/multi`,
                query: { group: gid },
              })
            : this.$router.push({
                path: `/contribute/${this.id}/0/group`,
                query: { group: gid },
              });
          break;
        default:
          this.$router.push({
            path: `/contribute/${this.id}/0/video`,
            query: { group: gid },
          });
          break;
      }
    },
    // getPath(type) {},
    share(type) {
      utils.shareTo(type);
    },
    showQrcode() {
      // document.getElementById("qrcode").innerHTML = "";
      let qrcode = new QRCode("qrcode", {
        // text: "千途导摄-赛事平台",
        width: 120, //图像宽度
        height: 120, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H, //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
      qrcode.clear(); //清除二维码
      // 使用移动端的地址
      let url = location.href;
      url = url.replace('match-fed', 'match-mobile');
      qrcode.makeCode(url); //生成另一个新的二维码
    },
    clearQrcode() {
      document.getElementById("qrcode").innerHTML = "";
    },
    checkUserInfo(callback) {
      this.axios.post("/match/checktUserInfo").then(
        (res) => {
          if (res.data.code == 200) {
            // 已登录
            store.commit("changeLogin", true);
            // 判断基础信息是否完整
            // console.log(res.data.data);
            let isValidated =
              res.data.data.mobile &&
              res.data.data.realname &&
              res.data.data.province &&
              res.data.data.city &&
              res.data.data.nickname;
            this.basicInfo.nickname = res.data.data.nickname;
            this.basicInfo.realname = res.data.data.realname;
            this.basicInfo.province = res.data.data.province;
            this.basicInfo.city = res.data.data.city;
            if (this.basicInfo.province != "") {
              this.city = this.province[this.basicInfo.province];
            }
            if (isValidated) {
              // 如果已经登记过信息，校验投稿分类
              this.isShowForm = false;
              this.uid = res.data.data.uid;
              // 俱乐部特殊判断
              if(this.allowClub == true){
                this.axios.get("/match/checkClub?club="+this.detail.club).then((res) => {
                  if (res.data.code != 200) {
                    this.allowClubDialog = true;
                    return false;
                  }else{
                    if (typeof callback == "function") {
                      callback();
                    }
                  }
                });
              }else{
                if (typeof callback == "function") {
                  callback();
                }
              }
            } else {
              // 否则先弹 基础信息 登记表单，提交登记表单后，跳转到校验投稿分类
              // 初始化已有的用户信息
              this.isShowForm = true;
            }
          }
        },
        (error) => {
          // 跳转到登录
          this.tools.ajax_error(error);
        }
      );
    },
    contributeGroup() {
      this.axios
        .post(this.tools.api.isTeamMember, { uid: this.uid, mid: this.id })
        .then((res) => {
          let data = res.data;
          if (data.code == 200 && data.data != null) {
            // 说明是战队成员
            if (this.isGroup) {
              // 进入战队投稿
              this.$router.push({
                path: "/team/" + this.id,
                query: {
                  // type: data.data.type,
                  tid: data.data.tid,
                  gid: this.gid,
                  // uid: data.data.uid,
                },
              });
            } else {
              // 进入个人投稿
              this.$alert("您已是战队成员。请点击\"战队投稿\"进行投稿", "提示", {
                dangerouslyUseHTMLString: true,
                type: "warning",
                center: true,
              });
            }
          } else if (data.code == 200 && data.data == null) {
            // 非战队成员
            if (this.isGroup) {
              // 进入战队投稿
              this.$alert("对不起，您不是战队成员。请点击\"我要投稿\"进行个人投稿", "提示", {
                dangerouslyUseHTMLString: true,
                type: "warning",
                center: true,
              });
            } else {
              // 进入个人投稿
              this.dealMatchGroup();
            }
          }
        });
    },
    goMyworks() {
      this.isMyworksActive = true;
      // 特定id才判断是否战队
      if (this.isShowGroupContribute) {
        this.checkUserInfo(() => {
          this.axios
            .post(this.tools.api.isTeamMember, { uid: this.uid, mid: this.id })
            .then((res) => {
              let data = res.data;
              if (data.code == 200 && data.data != null) {
                // 说明是战队成员
                this.$router.push({
                  path: "/team/" + this.id,
                  query: {
                    // type: data.data.type,
                    tid: data.data.tid,
                    gid: this.gid,
                    // uid: data.data.uid,
                  },
                });
              } else if (data.code == 200 && data.data == null) {
                // 非战队成员
                this.$router.push({
                  path: "/detail/" + this.id + "/myworks",
                });
              }
            });
        });
      } else {
        this.$router.push({
          path: "/detail/" + this.id + "/myworks",
        });
      }
    },
    toUploadOriImg(){
      this.$router.push({
          path: "/uploadOriImg/" + this.id,
        });
    }
  },
  created() {

  },
  mounted() {
    // 跳转路由之前会先执行这个
    this.init();
  },
};
</script>

<style lang="scss">
.content {
  .btn-group {
    background: #fff;
    color: #ef7f48;
  }
  .btn-tg {
   /*  bottom: 270px; */
   top:390px;
  }
  .btn-group1 {
    bottom: 420px;
  }
}
.body2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.btn-list {
  height: 320px;
  overflow: auto;
}
.share {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
#qrcode {
  position: absolute;
  top: -140px;
  left: 25px;
  img {
    padding: 5px;
    background: #fff;
    width: 120px;
    height: 120px;
    border-radius: 2px;
  }
}
.share,
.share .share-item {
  display: inline-block;
  text-align: center;
}
.share .share-item {
  margin: 0 10px 0 10px;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #fff;
  position: relative;
  img {
    width: auto;
    height: auto;
    max-width: 70%;
    max-height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.share .share-item canvas {
  position: absolute;
  top: -120px;
  left: -34px;
  padding: 5px;
  background: #fff;
  border: 1px solid #222;
  z-index: 9;
  display: none;
}
.share .weixin:hover canvas {
  display: block;
}
.dialog-selorg {
  .el-dialog {
    width: 420px;
  }
}
.el-dialog__header .el-dialog__close:hover {
  color: rgba(255, 255, 255, 0.6);
}
.dialog-basic {
  .el-dialog {
    width: 600px;
  }
  .btn-wrap {
    margin-bottom: 0;
  }
  .select {
    margin-right: 10px;
  }
}
.el-dialog__header {
  background: #ef7f48;
  padding: 10px 20px;
  .el-dialog__title,
  .el-dialog__close {
    color: #fff;
  }
  .el-dialog__headerbtn {
    top: 7px;
    right: 7px;
  }
  .el-dialog__close {
    font-size: 30px;
  }
}
.el-input__inner:focus,
.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus {
  border-color: #ef7f48;
}
.el-select-dropdown__item.selected {
  color: #ef7f48;
}
.dialog-type {
  .el-dialog {
    width: 320px;
  }
}
.btn-tgtype {
  display: block;
  width: 80%;
  margin: 10px auto;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #999;
  border-radius: 4px;
  &:hover {
    background: #ef7f48;
    color: #fff;
    border-color: #ef7f48;
  }
}
</style>
